@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.mood {
  display: flex;
  flex-direction: column;
  background-color: $white;
  background: linear-gradient(90deg, #E1F6EE 0%, #FBE3B8 100%);
  flex: 1;
  .content {
    @include content();
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;

    // .disabled {
    //   pointer-events: none;
    //   cursor: none;
    //   opacity: 0.5;
    // }

  }

  .bottom {
    @include content();
    text-align: center;
  }
}