@import './_mixins.scss';
@import './_variables.scss';

body {
  margin: 0;
  font-family: "DMSans-Regular";
  font-size: $regular;
  background-color: $bg-color;
  // scrollbar-gutter: stable;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: sans-serif;
}

html, body, #root, .App {
  min-height: 100%;
  // height: 100%;
  height: 100dvh;
}

a {
  color: inherit;
  text-decoration: underline;
}

h1 {
  @include h1();
  margin: 0 0 $margin-sm 0;
}

h2 {
  @include h2();
  margin: 0 0 $margin-sm 0;
}

h3 {
  @include h3();
  margin: 0 0 $margin-sm 0;
}

h4 {
  font-family: 'DMSans-Medium';
  font-weight: normal;
  font-size: $h4;
}

label,
p {
  font-size: $regular;
  color: $text-color;
  margin: 0 0 $margin-sm 0;
  &.small {
    font-size: $small;
  }
}

p {
  font-family: "DMSans-Regular";
}

a, li {
  font-size: $regular;
  font-family: "DMSans-Regular";
  color: $text-color;
}

button {
  font-family: "DMSans-SemiBold";
  color: $text-color;
  font-size: $regular;
  border: solid 1px $white;
  background-color: $white;
  color: $text-color;
  outline: inherit;
  box-shadow: none;
  padding: 0 $margin-md;
  height: 55px;
  border-radius: $radius;
  
  .icon {
    position: relative;
    top: 1px;
    left: -1px;
    vertical-align: middle;
    font-size: 18px;
    margin-right: 5px;
  }


  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    background-color: $grey;
  }

  &.secondary {
    border: solid 1px $secondary-color;
    background-color: $secondary-color;
  }

  &.invisible {
    padding: 0;
    border: none;
    background-color: transparent;
    color: $text-color;
  }

}

