@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.quote {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;

  audio {
    width: 100%;
    margin-top: $margin-sm;
  }

}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}