@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.willingness {
  flex: 1;
  width: 100%;

  ul {
    padding: 0;
    margin: $margin-md 0 0 0;
    li {
      list-style: none;
      margin-bottom: $margin-md;
      >div {
        background-color: $white;
        border-radius: $radius;
        padding: $margin-md;
        cursor: pointer;
        
        &.selected {
          background-color: $secondary-color
        }

        h4, p {
          color: $text-color !important;
        }

        h4 {
          margin-bottom: $margin-xs !important;
        }

        p {
          margin: 0;
        }

      }

    }
  }

}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  
}