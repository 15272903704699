@import '../../styles/variables';
@import '../../styles/mixins';


.splash {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  background-image: url('../../assets/images/haut.svg'), url('../../assets/images/bas.svg');
  background-position: top left, bottom right;
  background-repeat: no-repeat;

  .content {
    @include content();
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h1, p {
      color: $white;
      text-align: center
    }

    img {
      margin-bottom: $margin-lg;
    }

    .baseline {
      margin-bottom: $margin-md;

      h1 {
        font-family: 'HammersmithOne-Regular';
        font-size: 54px;
        line-height: 45px;
        margin-bottom: 0;
      }

      p {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    .greetings {
      font-family: 'DMSans-SemiBold';
      font-size: $h3;
    }

  }
}