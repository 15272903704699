@import "../../styles/_variables.scss";

.overlay-modal{
  position: fixed;
  z-index: 3000;
  top:0;
  height: 100dvh;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal{
  position: fixed;
  z-index: 3001;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 310px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $secondary-color;
    color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    transform: translate(50%,-50%);
    cursor: pointer;
  }

  p{
    margin-top: 0;
  }

  .container-btn{
    display: flex;
    justify-content: center;
    button:first-child{
      margin-right: 5px;
    }
    button:last-child{
      margin-left: 5px;
    }
  }
}
