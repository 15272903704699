@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.aspirations {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;

  .containerLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: $margin-xl 0;
    opacity: 0.5;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      margin-bottom: $margin-md;
      .aspiration {
        background-color: $white;
        border-radius: $radius;
        padding: $margin-md;
        cursor: pointer;
        
        &.selected {
          background-color: $secondary-color
        }

        h4, p {
          color: $text-color !important;
        }

        h4 {
          margin-bottom: $margin-xs !important;
        }

        p {
          margin: 0;
        }

      }

    }
  }

}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}