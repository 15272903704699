@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.intro {
  display: flex;
  flex-direction: column;
  flex: 1;
  .content {
    @include content();
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h1, p {
      color: $white;
      text-align: center
    }

    img {
      margin-bottom: $margin-lg;
    }

    .baseline {
      margin-bottom: $margin-md;

      h1 {
        font-family: 'HammersmithOne-Regular';
        font-size: 54px;
        line-height: 45px;
        margin-bottom: 0;
      }

      p {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

  }

  .bottom {
    @include content();
    text-align: center;

    button {
      margin-bottom: $margin-lg;
      width: 250px;
    }

    a {
      color: $white;
    }
  }
}