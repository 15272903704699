@import "../../styles/_variables.scss";

.container {
  border-radius: $radius;
  border: 1px solid rgba(55, 113, 91, 0.05);
  padding: 5px;
  width: 312px;

  &.gradient {
    background: linear-gradient(90deg, #E1F6EE 0%, #FBE3B8 100%);
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
    height: auto;
    img {
      width: 40px
    }
  }
}