@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.situation {
  flex: 1;
  width: 100%;

  .containerLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: $margin-xl 0;
    opacity: 0.5;
  }


  .disabled {
    textarea+p {
      display: none;
    }
  }

  .contentSituation {
    position: relative;

    img {
      display: block;
      width: 160px;
      margin: 0 auto $margin-md auto;
    }

    p {
      text-align: center;
    }

    p:nth-child(2) {
      font-size: $medium;
    }

    p:last-child {
      margin-top: 60px;
    }

  }

}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .next {
    margin-left: auto;
  }
}