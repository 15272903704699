@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.formulations {
  flex: 1;
  width: 100%;

  .error {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $margin-md;
  }

  .containerLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: $margin-md 0 $margin-xl 0 ;
    opacity: 0.5;
  }

  ul {
    margin: 0 0 $margin-md 15px;
    li {
      color: $white;
      margin-bottom: 5px;
      >div {
        background-color: rgba(0, 0, 0, 0.09);
        border-radius: $radius;
        padding: $margin-md;
      }

    }
  }

  .needHelp {
    background-color: $white;
    border-radius: $radius;
    padding: $margin-md;
    margin: $margin-md 0 $margin-xl 0;
    cursor: pointer;
    &.selected {
      background-color: $secondary-color
    }
    
    h4, p {
      color: $text-color;
    }
  }

}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}