@import "../../styles/_variables.scss";

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 64px;
  width: 64px;

  .profile {
    position: absolute;
    top: 50%;
    right: $margin-md;
    transform: translateY(-50%);
    button {
      color: $white;
    }
  }

}