@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.steps {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.lines {
    background-image: url('../../assets/images/haut.svg'), url('../../assets/images/bas.svg');
    background-position: top left, bottom right;
    background-repeat: no-repeat;
  }

  >.header {
    position: relative;
    z-index: 2;
    display: flex;
    >button {
      color: $white !important;
      height: 64px;
      width: 64px;
      &:nth-child(2) {
        margin-left: auto;
      }
    }
  }


  .content {
    @include content();
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h4, p {
      color: $white;
    }

    h4 {
      margin-bottom: $margin-sm;
    }

  }
}

