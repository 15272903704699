@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.comment {
  flex: 1;
  width: 100%;

  .containerInput {
    display: flex;
    justify-content: center;
    margin: $margin-lg 0;
  }

  >p {
    font-size: $regular-2;
    font-family: 'DMSans-SemiBold';
  }

}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}