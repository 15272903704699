@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.optionalFeelings {
  flex: 1;
  width: 100%;


  .header {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: solid 1px #fff;
    margin-bottom: $margin-lg;
    padding-bottom: $margin-sm;

    p, h2 {
      margin-bottom: 0;
      color: $white;
    }
  }


  ul {
    position: relative;
    z-index: 1;
    padding: 0;
    margin: $margin-lg 0 0 0;
    width: 100%;

    &.withValue {
      li:not(.selected) p {
        opacity: 0.5;
      }
    }

    li {
      list-style: none;
      border-bottom: solid 1px rgba(255, 255, 255, 0.06);
      padding: 12px 0;
      width: 100%;
      p {
        margin-bottom: 0;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}

.bottom {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
}