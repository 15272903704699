@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.home {
  display: flex;
  flex-direction: column;
  background-color: $white;
  flex: 1;
  .content {
    @include content();
    flex: 1;

    .header {
      img {
        margin-top: 5px;
        margin-bottom: $margin-md;
      }
      h3 {
        text-align: center;
      }
    }


    .header+div {
      .buttonBlock {
        display: block;
        background-color: $primary-color;
        max-width: 340px;
        height: auto;
        width: 100%;
        text-align: left;
        margin: 0 auto $margin-md auto;
        padding: $margin-sm $margin-md;

        &.white {
          background-color: transparent;
          border: solid 1px #DFDFDF;
          p {
            color: $text-color;
          }
        }

        img {
          display: block;
          margin: 0 auto $margin-sm auto;
          width: 150px;
        }

        p {
          font-family: 'DMSans-Medium';
          color: $white;
          margin-bottom: 5px;
          font-size: $medium;
          &.sub {
            font-family: 'DMSans-Regular';
            font-size: $small;
            margin-bottom: 0;
          }
        }
      }
    }

  }
}