$bg-color: #37715B;
$white: #FFFFFF;
$primary-color: #37715B;
$primary-light-color: rgba(50, 103, 83, 0.29);
$secondary-color: #FFE3B8;
$third-color: #B02E38; 
$warn: #F55B5D;
$light-grey: #F4F7FE;
$grey: #8F8F8F;
$black: #202D2F;

$border-grey: #717A97;

$text-color-black: #515151;
$text-color-light: #8F8F8F;
$text-color: #1F3E32;

$xl-screen: 1200px;
$lg-screen: 1024px;
$md-screen: 900px;
$sm-screen: 768px;
$xs-screen: 576px;

$margin-xl : 50px;
$margin-lg : 30px;
$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$h1: 32px;
$h2: 26px;
$h3: 24px;
$h4: 20px;
$medium: 18px;
$regular-2: 16px;
$regular: 14px;
$small: 12px;

$radius: 12px;


@font-face {
	font-family: "HammersmithOne-Regular";
	src: url("../assets/fonts/HammersmithOne-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "DMSans-Bold";
	src: url("../assets/fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "DMSans-SemiBold";
	src: url("../assets/fonts/DMSans-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "DMSans-Medium";
	src: url("../assets/fonts/DMSans-Medium.ttf") format("truetype");
}


@font-face {
	font-family: "DMSans-Regular";
	src: url("../assets/fonts/DMSans-Regular.ttf") format("truetype");
}


