@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.ideas {
  flex: 1;
  width: 100%;

  .containerLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: $margin-xl 0;
    opacity: 0.5;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      margin-bottom: $margin-md;
      >div {
        background-color: rgba(0, 0, 0, 0.09);
        border-radius: $radius;
        padding: $margin-md;
        p {
          margin-bottom: 0;
        }
      }

    }
  }

}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}