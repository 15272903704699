@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.feelings {
  flex: 1;
  width: 100%;
  background-attachment: fixed;

  h4 {
    position: relative;
    z-index: 1;
  }

  .feelingsRange {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #E1F6EE 0%, #FBE3B8 100%);
    height: 54px;
    border-radius: $radius;
    margin-bottom: $margin-sm;
    p {
      font-family: 'DMSans-SemiBold';
      font-size: $medium;
      display: inline-block;
      width: 50%;
      margin: 0;
      text-align: center;
      color: $primary-color !important;
    }
  }

  ul {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-gap: $margin-sm;
    margin-bottom: $margin-lg;

    li {
      margin: 0;
    }

    li:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }

    li:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    
    li:nth-child(3) {
      grid-area: 2 / 1 / 3 / 2;
    }

    li:nth-child(4) {
      grid-area: 2 / 2 / 3 / 3;
    }

    li:nth-child(5) {
      grid-area: 3 / 1 / 4 / 2;
    }

    li:nth-child(6) {
      grid-area: 4 / 1 / 5 / 2;
    }

    li:nth-child(7) {
      grid-area: 5 / 1 / 6 / 2;
    }

    li:nth-child(8) {
      grid-area: 6 / 1 / 7 / 2;
    }

    li:nth-child(9) {
      grid-area: 7 / 1 / 8 / 2;
    }

    button {
      width: 100%;
    }

  }

}

.bottom {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  
}