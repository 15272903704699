@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.end {
  flex: 1;
  width: 100%;

  button {
    width: 100%;
    margin-bottom: $margin-md;
    text-align: left;
  }


}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    width: 100%;
  }
}