@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.celebration {
  flex: 1;
  width: 100%;

  h4+p {
    margin-bottom: $margin-lg;
  }

  >div {
    margin-bottom: $margin-md;

    h6 {
      font-size: $small;
      color: $white;
      margin-bottom: 5px;
    }
  
    p {
      font-size: $regular-2;
    }
  }

}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}