@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.ressources {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $white;
  .content {
    @include content();
    display: flex;
    flex-direction: column;
    flex: 1;

    .header {
      img {
        margin-top: 5px;
        margin-bottom: $margin-md;
      }
      button {
        display: block;
        // position: absolute;
        // top: calc(50% + 2px);
        // left: 0;
        // transform: translate(0, -50%);
        color: $primary-color
      }
    }

            
    .image {
      position: relative;
      width: 100%;
      max-width: 500%;
      padding-top: 50%;
      border-radius: $radius;
      background-color: $grey;
      margin-bottom: $margin-lg;
      img {
        position: absolute;
        inset: 0;
        object-fit: cover;
        object-position: center;
      }
    }

    h4 {
      margin-bottom: $margin-md;
    }

  }
}