@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.ressources {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $white;
  .content {
    @include content();
    display: flex;
    flex-direction: column;
    flex: 1;

    .header {
      img {
        margin-top: 5px;
        margin-bottom: $margin-md;
      }
      .title {
        position: relative;
        text-align: center;
        margin-top: $margin-sm;
        margin-bottom: $margin-lg;
  
        h4 {
          margin-bottom: 0;
        }
  
        button {
          position: absolute;
          top: calc(50% + 2px);
          left: 0;
          transform: translate(0, -50%);
          color: $primary-color
        }
      }
    }

    .listRessources {
      .ressource {
        display: flex;
        margin-bottom: $margin-sm;
        cursor: pointer;

        h5 {
          font-size: $regular;
          margin-bottom: 5px;
        }

        p {
          font-size: $small;
        }
        
        .image {
          width: 100px;
          min-width: 95px;
          height: 60px;
          border-radius: $radius;
          background-color: $grey;
          box-shadow: 3px 5px 10px rgba(224, 224, 230, 0.7);
          overflow: hidden;
          
          img,
          video {
            // object-fit: cover;
            width: 100%;
            height: 100%;
          }
          
        }
        .contentRessource {
          padding-left: $margin-md;
        }
      }
    }

  }
}